<template>
    <div></div>
</template>

<script>
import axios from 'axios';
export default
{
    name: 'Audio',
    data()
    {
        return {
            // messages: [],
            txts: []
        }
    },
    computed: { },
    methods:
    {
        get(txt)
        {
            let id_ = "_" + Math.ceil(Math.random() * 99999);
            this.txts.push({ "id": id_, "txt": txt });
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access'      : 'application/json',
            }

            let form_data = new FormData();
            form_data.append('id_', id_);
            form_data.append('phrase', txt);
            form_data.append('audio_name', ("avia" + Math.ceil(Math.random() * 99999)));
            form_data.append('language', this.$parent.avia.recognition_language);
            // form_data.append('voice', this.$parent.avia.translation_language);
            form_data.append('voice', this.$parent.avia.assistant_voice);
            form_data.append('api_url', '');

            axios.post(this.$url_api + "api/ai/text-to-audio", form_data, { headers: headers })
                .then((response) => this.getResponse(response))
                .catch((error) => this.getError(error.response));
        },
        getResponse(obj)
        {
            this.$parent.audioStop()
            let current_txt = this.txts.find(x => (x.id == obj.data.id_));
            this.$parent.sendToUnity('SetVoice', { audioclip: (this.$parent.avia.generate_output_voice ? obj.data.response.audio_url : ''), txt: current_txt.txt, unity_id:this.$parent.unity_id });
        },
        getError(obj)
        {
            console.log('getError => ', obj);
        }
    },
    mounted() { }
}
</script>