<template>
    <div></div>
</template>

<script>
export default
{
    name: 'Personality',
    data()
    {
        return {
            
        }
    },
    computed:
    {

    },
    methods:
    {
        setPersonality(avia, dialogs)
        {
            // console.log('setPersonality =>', avia.system_dialog);
            
            if (avia.system_dialog)
            {
                let persona = [];
                let system  = avia.system_dialog;
                /*
                if (dialogs)
                {
                    for (let i = 0; i < dialogs.length; i++)
                    {
                        if (dialogs[i].assistant_dialog)
                        {
                            // system += (dialogs[i].assistant_dialog + '\n');
                            system += ('Pergunta: ' + dialogs[i].user_dialog + '\n Resposta: ' + dialogs[i].assistant_dialog + '\n');
                        }
                    }
                }
                */
                let system_per = {
                    "role"      : "system",
                    "content"   : system
                }
                persona.push(system_per);
                
                if (dialogs)
                {
                    for (let i = 0; i < dialogs.length; i++)
                    {
                        if (dialogs[i].user_dialog && dialogs[i].assistant_dialog)
                        {
                            let user        =
                            {
                                "role"   : "user",
                                "content": dialogs[i].user_dialog
                            };
                            let assistant   =
                            {
                                "role"   : "assistant",
                                "content": dialogs[i].assistant_dialog
                            };
                            persona.push(user);
                            persona.push(assistant);
                        }
                    }
                }
                
                
                return persona;
            }
            

            /*
            if (avia.system_dialog)
            {
                let persona = { messages: [] };
                let system  = avia.system_dialog;
                let system_per = {
                    "role"      : "system",
                    "content"   : system
                }
                persona.messages.push(system_per);

                if (dialogs)
                {
                    for (let i = 0; i < dialogs.length; i++)
                    {
                        if (dialogs[i].user_dialog && dialogs[i].assistant_dialog)
                        {
                            let user        =
                            [
                            {
                                "role"   : "user",
                                "content": dialogs[i].user_dialog
                            },
                            
                            {
                                "role"   : "assistant",
                                "content": dialogs[i].assistant_dialog
                            }];
                            persona.messages.push(user);
                            // persona.messages.push(assistant);
                        }
                    }
                }
                
                return persona;
            }*/
            return [];
        }
    },
    mounted()
    {
        if (this.$parent.avia)
        {
            this.$parent.personality = this.setPersonality(this.$parent.avia, this.$parent.dialogs);
            if (this.$parent.personality)
            {
                // this.$parent.$refs['chat'].messages = this.$parent.personality;
                this.$parent.count = this.$parent.personality.length;
            }
        }
    }
}
</script>